// eslint-disable-next-line lodash/import-scope
import startsWith from 'lodash/startsWith';
var HOME_URI = '__home__';

var createUriHelper = function createUriHelper(baseUrl, authEndpointUrl, locale, legacyAppUrl, urls) {
  if (urls === void 0) {
    urls = {
      artworks: 'artworks',
      artists: 'artists'
    };
  }

  return {
    ensureAbsoluteUrl: function ensureAbsoluteUrl(url) {
      if (startsWith(url, 'http')) {
        return url;
      }

      return "" + baseUrl + (locale ? "/" + locale : '') + url;
    },
    getCmsPageUrl: function getCmsPageUrl(pageUri) {
      if (pageUri === HOME_URI) {
        return this.getHomeUrl();
      }

      return "/" + pageUri + "/";
    },
    getArtworksUrl: function getArtworksUrl() {
      return "/" + urls.artworks + "/";
    },
    getArtworkUrl: function getArtworkUrl(slug) {
      return "/" + urls.artworks + "/" + slug + "/";
    },
    getArtistUrl: function getArtistUrl(slug) {
      return "/" + urls.artists + "/" + slug + "/";
    },
    getCuratorUrl: function getCuratorUrl(slug) {
      return "/curators/" + slug + "/";
    },
    getGalleryUrl: function getGalleryUrl(slug) {
      return "/galleries/" + slug + "/";
    },
    getExhibitionsUrl: function getExhibitionsUrl() {
      return "/drops/";
    },
    getExhibitionUrl: function getExhibitionUrl(slug, initSlider) {
      return initSlider || initSlider === 0 ? "/drops/" + slug + "/?initSl=" + initSlider : "/drops/" + slug + "/";
    },
    getInstagramPostUrl: function getInstagramPostUrl(postShortId) {
      return "https://www.instagram.com/p/" + postShortId;
    },
    getHomeUrl: function getHomeUrl() {
      return "/";
    },
    getAboutUrl: function getAboutUrl() {
      return "/about/";
    },
    getBlogUrl: function getBlogUrl(slug) {
      return slug ? "/blog/" + slug + "/" : "/blog/";
    },
    getLoginUrl: function getLoginUrl() {
      return "/login/";
    },
    getRegisterUrl: function getRegisterUrl() {
      return "/register/";
    },
    getEmailVerificationUrl: function getEmailVerificationUrl() {
      return "/profile/email-verification/";
    },
    getCollectionsUrl: function getCollectionsUrl() {
      return "/profile/collection/";
    },
    getProfileEditUrl: function getProfileEditUrl() {
      return "/profile/edit/";
    },
    getProfileCompletedUrl: function getProfileCompletedUrl() {
      return "/profile/completed/";
    },
    getAuthUrl: function getAuthUrl(provider, redirectionTarget) {
      return authEndpointUrl + "/auth/" + provider + "?test=1&redir=" + redirectionTarget;
    },
    getTermsAndConditionsUrl: function getTermsAndConditionsUrl() {
      return this.getCmsPageUrl(CONFIG.cmsPageUris.termsAndConditions);
    },
    getPrivacyPolicyUrl: function getPrivacyPolicyUrl() {
      return this.getCmsPageUrl(CONFIG.cmsPageUris.privacyPolicy);
    },
    getBrowserNotSupported: function getBrowserNotSupported() {
      return this.getCmsPageUrl(CONFIG.cmsPageUris.browserNotSupported);
    },
    getArtworkCheckoutUrl: function getArtworkCheckoutUrl(slug) {
      return this.getArtworkUrl(slug) + "checkout/";
    },
    getApproveTokenWithdrawalUrl: function getApproveTokenWithdrawalUrl() {
      return "/approve-token-withdrawal/";
    },
    getApprovePayoutWalletUrl: function getApprovePayoutWalletUrl() {
      return "/approve-payout-wallet-update/";
    },
    getPostPreviewUrl: function getPostPreviewUrl() {
      return "/post-preview/";
    },
    getTvConnectUrl: function getTvConnectUrl() {
      return legacyAppUrl + "/connect/welcome";
    },
    getMyPortfolioUrl: function getMyPortfolioUrl() {
      return legacyAppUrl + "/artist";
    }
  };
};

export { createUriHelper };