import { useMemo } from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import { createUriHelper } from '@/common/utils/uri-helper';

function useUri(appUrl) {
  var _useIntl = useIntl(),
      locale = _useIntl.locale;

  var uriHelper = useMemo(function () {
    var _CONFIG, _CONFIG2, _CONFIG3, _CONFIG4, _CONFIG5;

    return createUriHelper(appUrl || ((_CONFIG = CONFIG) === null || _CONFIG === void 0 ? void 0 : _CONFIG.appUrl), (_CONFIG2 = CONFIG) === null || _CONFIG2 === void 0 ? void 0 : _CONFIG2.endpoints.authApi, locale || ((_CONFIG3 = CONFIG) === null || _CONFIG3 === void 0 ? void 0 : _CONFIG3.defaultLocale), (_CONFIG4 = CONFIG) === null || _CONFIG4 === void 0 ? void 0 : _CONFIG4.endpoints.legacyApp, (_CONFIG5 = CONFIG) === null || _CONFIG5 === void 0 ? void 0 : _CONFIG5.pageUris);
  }, [appUrl, locale]);
  return {
    uriHelper: uriHelper
  };
}

export { useUri };